import { Component, HostListener, OnInit } from '@angular/core';
import { Base } from 'src/app/interfaces/base';
import { GeneralService } from 'src/app/services/general.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-service-gallery',
  templateUrl: './service-gallery.component.html',
  styleUrls: ['./service-gallery.component.scss']
})
export class ServiceGalleryComponent implements OnInit {

  galleries: Base[] = [];

  constructor(private generalService: GeneralService) {
    this.generalService.serviceGallery.subscribe(res => {
      this.galleries = res;

    })
  }

  uploadUrl = environment.uploadUrl;

  display;

  @HostListener('window:resize')
  function() {
    // console.log('resized')
    this.display = 'none';
    setTimeout(() => {
      this.display = 'block';
    }, 500);
  }

  customMenuOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    // pullDrag: true,
    freeDrag: true,
    startPosition: 3,
    dots: false,
    // navSpeed: 300,
    navText: [`<i class="far fa-arrow-alt-circle-left"></i>`, `<i class="far fa-arrow-alt-circle-right"></i>`],
    responsive: {
      0: {
        items: 1,
      },
      800: {
        items: 4,
      },
      1100: {
        items: 4,
      },
    },
    autoHeight: false,
    autoWidth: true,
    nav: true,
  };

  ngOnInit(): void {

  }

}
