import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Base } from 'src/app/interfaces/base';
import { AboutsService } from 'src/app/services/abouts.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  slug: string;

  uploadUrl = environment.uploadUrl;

  service = this.aboutService;

  data: Base;
  constructor(
    private domSanit: DomSanitizer,
    private acRoute: ActivatedRoute,
    private router: Router,
    private aboutService: AboutsService) {

    this.acRoute.params.subscribe(data => {
      this.slug = data.slug;
      this.getDetail(data.slug);
    })

  }


  ngOnInit() {



  }

  getSanit(data) {
    return this.domSanit
      .bypassSecurityTrustHtml(data)
  }


  getDetail(slug) {
    this.aboutService.detail<Base>(slug).subscribe(res => {
      if (res) {
        this.data = res;
      } else {
        this.router.navigate(['error']);
      }

    })
  }

}
