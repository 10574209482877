import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private generalService: GeneralService,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) { }

  form = this.fb.group({
    fullName: [null, Validators.required],
    email: [null],
    subject: [null, Validators.required],
    content: [null, Validators.required],
    phone: [null, Validators.required]
  });


  send() {
    // console.log(this.form.value);
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.cd.detectChanges();
    if (this.form.valid) {
      this.generalService.postContactForm(this.form.value).subscribe(data => {
        Swal.fire({
          icon: 'success',
          title: 'Başarıyla Gönderildi'
        }).then(res => {
          if (res.isConfirmed) {
            this.router.navigateByUrl('');
          }
          this.form.reset();
        });
      }, (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Bir sorun oluştu! Daha sonra tekrar deneyin.'
        })
      });
    }

   
  }

  getError(name) {
    return this.form.get(name).touched && this.form.get(name).hasError('required');
  }

  ngOnInit(): void {
  }

}
