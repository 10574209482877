import { Component, Input, OnInit } from '@angular/core';
import { Base } from 'src/app/interfaces/base';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  @Input()
  prefix: string = '';

  @Input()
  service: BaseService;

  @Input()
  title: string;
  
  categories: Base[] = [];


  constructor() {
    
   }

  ngOnInit(): void {

    if(this.service) {
      this.service.list<Base[]>().subscribe(data => {
        this.categories = data;
      })
    }

  }

}
