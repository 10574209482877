<footer class="footer-area">
    <div class="container">


        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/SD_0002.jfif" alt="image"
                                style="height: 100px; width: 200px; object-fit: contain;"></a>
                        <p> {{contact?.short_about}} </p>
                    </div>
                    <ul class="social">
                        <li *ngIf="contact?.facebook"><a [href]="safeUrl(contact?.facebook)" target="_blank"><i
                                    class="flaticon-facebook"></i></a></li>
                        <li *ngIf="contact?.linkedin"><a [href]="safeUrl(contact?.linkedin)" target="_blank"><i
                                    class="flaticon-linkedin"></i></a></li>
                        <li *ngIf="contact?.instagram"><a [href]="safeUrl(contact?.instagram)" target="_blank"><i
                                    class="flaticon-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Hızlı Linkler</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Ana Sayfa</a></li>
                        <li *ngFor="let item of hizmetler"><a routerLink="/hizmetler/{{item.slug}}">
                                {{item.title}}</a></li>
                         
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                <div class="single-footer-widget">
                    <h3>İletişim Bilgileri</h3>
                    <ul class="footer-contact-info">
                        <li><span>Konum:</span> {{contact?.address}} </li>
                        <li><span>Email:</span> <a href="mailto:{{contact?.email1}} "> {{contact?.email1}} </a></li>
                        <li><span>Telefon:</span> <a href="tel: {{contact?.phone1}} "> {{contact?.phone1}} </a></li>
                        <li><span>Faks:</span> <a [href]="safeUrl('fax:' + contact?.fax1)"> {{contact?.fax1}} </a></li>
                        <li><a [href]="safeUrl(contact?.google_url)" target="_blank">GoogleMap'te görüntüle</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p><i class="far fa-copyright"></i> 2020 Tüm hakkı saklıdır. Design by <a
                            href="https://roisoft.net/" target="_blank">RoiSoft</a></p>
                </div>

                <!-- <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/sozlesme/privacy_policy">Gizlilik Sözleşmesi</a></li>
                        <li><a routerLink="/sozlesme/terms_and_conditions">Hüküm ve Koşullar</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="fas fa-chevron-up"></i><i class="fas fa-chevron-up"></i></div>