import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Base } from 'src/app/interfaces/base';
import { ServicesService } from 'src/app/services/services.service';
import { AboutsService } from 'src/app/services/abouts.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {

    location: any;
    navbarClass: any;
    logo: any;
    navContainer: any;

    hizmetler: Base[] = [];
    hakkimizda: Base[] = [];

    constructor(
        private router: Router,
        location: Location,
        private hizmetService: ServicesService,
        private hakkimizdaService: AboutsService,
    ) {

        this.hizmetService.servicesList().subscribe(data => {
            this.hizmetler = data;
        });
        this.hakkimizdaService.list<Base[]>().subscribe(data => {
            this.hakkimizda = data;
        })
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    // console.log(event);
                    this.location = this.router.url;
                    if (this.location == '/') {
                        this.navbarClass = 'navbar-area';
                        this.navContainer = 'container';
                        this.logo = 'assets/img/SD_0004.jfif';
                        // } else if (this.location == '/home-2'){
                        //     this.navbarClass = 'navbar-area navbar-style-two';
                        //     this.navContainer = 'container-fluid';
                        //     this.logo = 'assets/img/SD_0004.jfif';
                        // } else if (this.location == '/home-3'){
                        //     this.navbarClass = 'navbar-area navbar-style-two';
                        //     this.navContainer = 'container-fluid';
                        //     this.logo = 'assets/img/SD_0004.jfif';
                    } else {
                        this.navbarClass = 'navbar-area';
                        this.navContainer = 'container';
                        this.logo = 'assets/img/SD_0004.jfif';
                    }
                }
            });
    }

    ngOnInit() {
    }

}
