import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  protected apiUrl = environment.apiUrl;
  protected prefix = '';

  constructor(protected httpClient: HttpClient) { }


  detail<T>(slug: string): Observable<T> {
    return this.httpClient.get<T>(this.apiUrl + this.prefix + slug);
  }

  post<T>(data: T): Observable<T> {
    return this.httpClient.post<T>(this.apiUrl + this.prefix, data);
  }

  list<T>(): Observable<T> {
    return this.httpClient.get<T>(this.apiUrl + this.prefix);
  }

  altCategoriesDetail<T>(slug: string): Observable<T> {
    return this.httpClient.get<T>(this.apiUrl + 'alt/' + this.prefix + 'detail/' + slug);
  }

  altCategories<T>(id: number): Observable<T> {
    return this.httpClient.get<T>(this.apiUrl + 'alt/' + this.prefix + id);
  }

  categoryList<T>(): Observable<T> {
    return this.httpClient.get<T>(this.apiUrl + this.prefix + 'categories/');
  }
}
