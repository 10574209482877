<section class="partner-area ptb-100 bg-f8f8f8">
    <div class="container">
        <div class="partner-title">
            <h2>İş Ortaklarımız</h2>
        </div>

        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                    <img src="//via.placeholder.com/1920x1080" alt="image" style="height: 100px;object-fit: contain;">
            </div>

            <div class="single-partner-item">
                    <img src="//via.placeholder.com/1000x300" alt="image" style="height: 100px;object-fit: contain;">
            </div>

        </div>
    </div>
</section>