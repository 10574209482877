import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { setting } from 'src/app/data';
import { Base } from 'src/app/interfaces/base';
import { Setting } from 'src/app/interfaces/setting';
import { ServicesService } from 'src/app/services/services.service';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  contact: Setting;

  hizmetler: Base[] = [];

  constructor(
    private settingService: SettingService,
    private domSanitizer: DomSanitizer,
    private hizmetlerService: ServicesService,
  ) { 
    this.settingService.list<Setting>().subscribe(data => {
      this.contact = data;
    });
    this.hizmetlerService.servicesList().subscribe(data => {
      this.hizmetler = data;
    });
  }

  ngOnInit() {
    // this.settingService.
  }

  safeUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
