<section class="services-area ptb-100" *ngIf="data.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Neden Biz?</h2>

        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of data">
                <div class="single-services-box" style="min-height: 250px;">


                    <h3> {{item?.title}} </h3>

                    <p> {{item?.content}} </p>


                    <!-- <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div> -->
                </div>
            </div>


        </div>
    </div>
</section>