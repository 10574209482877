<app-breadcrumbs [title]="title" [subTitle]="'Sözleşme'" [imageType]="'iletisim_banner'"></app-breadcrumbs>


<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="p-5" [innerHTML]="getSanit(data?.sozlesme)"></div>
            </div>


        </div>
    </div>
</section>