import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';

import { ErrorComponent } from './pages/error/error.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { HizmetlerComponent } from './pages/hizmetler/hizmetler.component';
import { TrustedCompaniesComponent } from './components/trusted-companies/trusted-companies.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { HttpClientModule } from '@angular/common/http';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceGalleryComponent } from './components/service-gallery/service-gallery.component';
import { WhyUsComponent } from './components/why-us/why-us.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { SozlesmeComponent } from './pages/sozlesme/sozlesme.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CategoriesComponent } from './components/categories/categories.component';
import { AltKategoriComponent } from './pages/alt-kategori/alt-kategori.component';
import { UniversalInterceptorService } from './interceptors/universal-interceptor.service';
import { PrebootModule } from 'preboot';
const eventSelectors: any[] = [
  { selector: 'input,textarea', events: ['keypress', 'keyup', 'keydown', 'input', 'change'] },
  { selector: 'select,option', events: ['change'] },
  { selector: 'input', events: ['keyup'], preventDefault: true, keyCodes: [13], freeze: true },
  { selector: 'form', events: ['submit'], preventDefault: true, freeze: true },
  { selector: 'input,textarea', events: ['focusin', 'focusout', 'mousedown', 'mouseup'], replay: false },
  { selector: 'button', events: ['click'], preventDefault: true, freeze: true }
];

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    ErrorComponent,
    HeaderComponent,
    HizmetlerComponent,
    TrustedCompaniesComponent,
    FeedbackComponent,
    ContactFormComponent,
    ServiceGalleryComponent,
    WhyUsComponent,
    BreadcrumbsComponent,
    SozlesmeComponent,
    CategoriesComponent,
    AltKategoriComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    PrebootModule.withConfig({ appRoot: 'app-root' ,eventSelectors}),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
  ],
  providers: [UniversalInterceptorService],
  bootstrap: [AppComponent]
})
export class AppModule { }
