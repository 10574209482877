<app-breadcrumbs [title]="data?.title" [imageType]="'hakkimizda_banner'"></app-breadcrumbs>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row">


            <div class="col-lg-3 col-md-12">
                <aside class="widget-area" id="secondary">

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Hakkımızda</h3>

                        <ul>
                            <li><a routerLink="/hakkimizda/biz-kimiz">Biz Kimiz</a></li>
                            <li><a routerLink="/hakkimizda/degerlerimiz">Değerlerimiz</a></li>
                            <li><a routerLink="/hakkimizda/ekibimiz">Ekibimiz</a></li>
                            <li><a routerLink="/hakkimizda/hizmet-anlayisimiz">Hizmet Anlayışımız</a></li>

                        </ul>
                    </section> -->


                    <app-categories title="Hakkımızda" prefix="/hakkimizda/" [service]="service"></app-categories>


                </aside>
            </div>

            <div class="col-lg-9 col-md-12">
                <div>
                    <h2>
                        {{data?.title}}
                    </h2>
                </div>
                <div class="p-5 about-image"  *ngIf="data?.image">
                    <div class="img1">
                    <img src="{{uploadUrl +data?.image}}" style="height: 225px; object-fit: contain;" alt="">
                        
                    </div>

                </div>
                <div class="p-5 html-content" [innerHTML]="getSanit(data?.content)"></div>
            </div>

        </div>
    </div>
</section>

<!-- <section class="our-mission-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-image bg-1">
                    <img src="assets/img/mission-img1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-text">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>Our Mission</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                    <a routerLink="/about" class="default-btn">Learn More <span></span></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-image bg-2">
                    <img src="assets/img/mission-img2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-text">
                    <div class="icon">
                        <i class="flaticon-award"></i>
                    </div>
                    <h3>Our History</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                    <a routerLink="/about" class="default-btn">Learn More <span></span></a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Meet Our Experts</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img2.jpg" alt="image">
                    <ul class="social">
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Lee Munroe</h3>
                    <span>Lead Designer</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img3.jpg" alt="image">
                    <ul class="social">
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Calvin Klein</h3>
                    <span>Lead Developer</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img4.jpg" alt="image">
                    <ul class="social">
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Sarah Taylor</h3>
                    <span>Lead Architecure</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img1.jpg" alt="image">
                    <ul class="social">
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Alastair Cook</h3>
                    <span>Marketing Manager</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="join-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="join-content">
            <h2>Risox is a community where everyone is welcome.</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <a routerLink="/contact" class="default-btn">Join Now <i class="flaticon-right-chevron"></i><span></span></a>
        </div>
    </div>
</section> -->
