<app-breadcrumbs [title]="data?.title" [subTitle]="categoryName" [imageType]="'hizmet_banner'"></app-breadcrumbs>


<section class="about-section ptb-100">
    <div class="container">
        <div class="row">

            <div class="col-lg-3 col-md-12">
                <aside class="widget-area" id="secondary">


                    <section class="widget widget_categories mb-5">
                        <h3 class="widget-title">{{mainCategory?.title}}</h3>

                        <ul>
                            <li *ngFor="let category of altCategories"><a [routerLink]="categoryPrefix+mainCategorySlug+'/alt/' + category.slug">
                            {{category.title}}
                            </a></li>

                        </ul>
                    </section>









                    <!-- <app-categories [prefix]="categoryPrefix" [service]="service"></app-categories> -->


                </aside>
            </div>

            <div class="col-lg-9 col-md-12">
                <div>
                    <h2>
                        {{data?.title}}
                    </h2>
                </div>
                <div class="p-5 about-image" *ngIf="data?.image">
                    <div class="img1">
                        <img src="{{uploadUrl + data?.image}}" style="height: 200px; object-fit: contain;" [alt]="data?.slug">

                    </div>

                </div>
                <div class="p-5 html-content" [innerHTML]="getSanit(data?.content)"></div>
            </div>


        </div>
    </div>
</section>