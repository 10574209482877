<section class="services-area ptb-100 bg-f8f8f8" *ngIf="galleries.length > 0">
    <div class="container">
        <!-- <div class="section-title">
            <h2>Hizmetlerimiz</h2>
        </div> -->

        <!-- <ng-container *ngIf="display == 'none' ? false : true">
            <owl-carousel-o [options]="customMenuOptions">
                <ng-container *ngFor="let slide of galleries">
                    <ng-template carouselSlide [id]="slide.id" class="">
                        <div
                            class="m-1 dark-services-box position-relative"
                            routerLink="/hizmetler/{{ slide?.link }}"
                        >
                            <img
                                *ngIf="slide?.image"
                                src="{{ uploadUrl + slide?.image }}"
                                alt="{{ slide?.title }}"
                                style="
                                    position: absolute;
                                    object-fit: contain;
                                    filter: blur(3px);
                                "
                            />
                            <div
                                class="p-2 services-content"
                                style="
                                    display: flex;
                                    min-height: 150px;
                                    justify-content: center;
                                    align-items: center;
                                "
                            >
                                <h3>{{ slide?.title }}</h3>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </ng-container> -->
        <div class="row col-md-12">
            <div class="col-md-3" *ngFor="let slide of galleries">
                <a [routerLink]="['/hizmetler', slide?.link]" >
                    <div class="m-1 dark-services-box position-relative" >
                        <img *ngIf="slide?.image" src="{{ uploadUrl + slide?.image }}" alt="{{ slide?.title }}" style="
                                position: absolute;
                                object-fit: contain;
                                filter: blur(3px);
                            " />
                        <div class="p-2 services-content" style="
                                display: flex;
                                min-height: 150px;
                                justify-content: center;
                                align-items: center;
                            ">
                            <h3>{{ slide?.title }}</h3>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>