import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trusted-companies',
  templateUrl: './trusted-companies.component.html',
  styleUrls: ['./trusted-companies.component.scss']
})
export class TrustedCompaniesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
