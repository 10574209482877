import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input()
  title:string;


  @Input()
  subTitle:string;

  @Input()
  imageType:string = 'blog_banner';


  image = 'banner.jpeg';

  uploadUrl = environment.uploadUrl;

  constructor(private generalService: GeneralService) {
    
     
   }

  ngOnInit(): void {
    this.generalService.getImage(this.imageType).subscribe(res => {
      // console.log(res);
     this.image = res.image;
   })
  }

}
