import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ErrorComponent } from './pages/error/error.component';
import { HizmetlerComponent } from './pages/hizmetler/hizmetler.component';
import { SozlesmeComponent } from './pages/sozlesme/sozlesme.component';
import { AltKategoriComponent } from './pages/alt-kategori/alt-kategori.component';

const routes: Routes = [
    
    {path: '', component: HomeComponent},
    {path: 'hakkimizda/:slug', component: AboutComponent},
    // {path: 'kurumsal', component: KurumsalComponent},
    {path: 'hizmetler/:slug', component: HizmetlerComponent},
    {path: 'hizmetler/:categorySlug/alt/:slug', component: AltKategoriComponent , data: {category: 'hizmetler'}},
    // {path: 'hakkimizda/:categorySlug/alt/:slug', component: AltKategoriComponent , data: {category: 'hakkimizda'}},
    {path: 'iletisim', component: ContactComponent},
    // {path: 'sozlesme/:slug', component: SozlesmeComponent},
    {path: 'hakkimizda', redirectTo: ''},

   
    {path: 'error', component: ErrorComponent},
    
    // Here add new pages component

    {path: '**', redirectTo:'error'} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
    exports: [RouterModule]
})
export class AppRoutingModule { }