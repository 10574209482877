import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
} from "@angular/router";
import {
    isPlatformBrowser,
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter } from "rxjs/operators";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { EventReplayer } from "preboot";
declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent implements OnInit, AfterViewInit {
    location: any;
    routerSubscription: any;

    @ViewChild(PreloaderComponent, { static: false }) appPreloader: PreloaderComponent;

    constructor(private router: Router,
        @Inject(PLATFORM_ID) private platformId,
        private replayer: EventReplayer,) { }

    ngOnInit() {
        this.recallJsFuntions();
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            // console.log(this.platformId)
            setTimeout(() => {
                this.replayer.replayAll();
            }, 0);
        }
    }

    recallJsFuntions() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.appPreloader) {
                    this.fadeIn(this.appPreloader.preloader.nativeElement, 0);
                }
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                if (this.appPreloader) {
                    this.fadeOut(this.appPreloader.preloader.nativeElement, 1000);
                }
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                // window.scrollTo(0, 0);
            });
    }

    fadeIn(elem, ms) {
        if (!elem) return;

        elem.style.opacity = 0;
        elem.style.filter = "alpha(opacity=0)";
        elem.style.display = "inline-block";
        elem.style.visibility = "visible";

        if (ms) {
            var opacity = 0;
            var timer = setInterval(function () {
                opacity += 50 / ms;
                if (opacity >= 1) {
                    clearInterval(timer);
                    opacity = 1;
                }
                elem.style.opacity = opacity;
                elem.style.filter = "alpha(opacity=" + opacity * 100 + ")";
            }, 50);
        } else {
            elem.style.opacity = 1;
            elem.style.filter = "alpha(opacity=1)";
        }
    }

    fadeOut(elem, ms) {
        if (!elem) return;

        if (ms) {
            var opacity = 1;
            var timer = setInterval(function () {
                opacity -= 50 / ms;
                if (opacity <= 0) {
                    clearInterval(timer);
                    opacity = 0;
                    elem.style.display = "none";
                    elem.style.visibility = "hidden";
                }
                elem.style.opacity = opacity;
                elem.style.filter = "alpha(opacity=" + opacity * 100 + ")";
            }, 50);
        } else {
            elem.style.opacity = 0;
            elem.style.filter = "alpha(opacity=0)";
            elem.style.display = "none";
            elem.style.visibility = "hidden";
        }
    }
}
