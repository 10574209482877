import { Component, OnInit } from '@angular/core';
import { Base } from 'src/app/interfaces/base';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.scss']
})
export class WhyUsComponent implements OnInit {

  data: Base[] = [];
  constructor(private generalService: GeneralService) {
    this.generalService.whyUs.subscribe(res => {
      this.data = res;
    })
   }

  ngOnInit(): void {
  }

}
