<div class="banner-section" style="background-image: url({{uploadUrl}}{{setting?.home_banner}});">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1> {{setting?.home_content}} </h1> -->
                    <!-- <p>Yazı gelecek.</p> -->
                    <!-- <form>
                        <div class="form-group mb-0">
                            <input type="text" class="input-location" placeholder="Enter Location">
                            <i class="flaticon-location"></i>
                        </div>
                        <button class="default-btn">Search Now <i class="fas fa-search"></i><span></span></button>
                    </form> -->
                </div>
            </div>
        </div>
    </div>
</div>





<app-service-gallery></app-service-gallery>

<!-- <section class="benefits-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Neden Biz</h2>
            <p>Neden biz yazısı.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 row">
                <div class="single-benefits-box">
                    
                    <h3>Creative Space</h3>
                    <p>Lorem ipsum dolor sit amet, cn consectetur adipiscing elit.</p>
                </div>

                <div class="single-benefits-box">
                    
                    <h3>High Speed Wifi</h3>
                    <p>Lorem ipsum dolor sit amet, cn consectetur adipiscing elit.</p>
                </div>

                <div class="single-benefits-box">
                    
                    <h3>Car Sharing</h3>
                    <p>Lorem ipsum dolor sit amet, cn consectetur adipiscing elit.</p>
                </div>

                <div class="single-benefits-box">
                    
                    <h3>24/7 Access</h3>
                    <p>Lorem ipsum dolor sit amet, cn consectetur adipiscing elit.</p>
                </div>

                <div class="single-benefits-box">
                    
                    <h3>Great Location</h3>
                    <p>Lorem ipsum dolor sit amet, cn consectetur adipiscing elit.</p>
                </div>

                <div class="single-benefits-box">
                    
                    <h3>Weekly Events</h3>
                    <p>Lorem ipsum dolor sit amet, cn consectetur adipiscing elit.</p>
                </div>

            </div>
          
        </div>
    </div>
</section> -->




<app-why-us></app-why-us>



<!-- <app-feedback></app-feedback> -->

<!-- <app-trusted-companies></app-trusted-companies> -->


<section class="join-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="join-content">
            <h2>İletişime Geçin</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
            <a routerLink="/iletisim" class="default-btn">Bize ulaşın <i
                    class="flaticon-right-chevron"></i><span></span></a>
        </div>
    </div>
</section>