import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Base } from 'src/app/interfaces/base';
import { AboutsService } from 'src/app/services/abouts.service';
import { BaseService } from 'src/app/services/base.service';
import { ServicesService } from 'src/app/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alt-kategori',
  templateUrl: './alt-kategori.component.html',
  styleUrls: ['./alt-kategori.component.scss']
})
export class AltKategoriComponent implements OnInit {

  slug: string;

  category: string;


  service: BaseService;

  categoryPrefix;

  categoryName: string;
  mainCategorySlug: string;
  mainCategory: Base;

  altCategories = [];
  uploadUrl = environment.uploadUrl;

  data: Base;
  constructor(
    private servicesService: ServicesService,
    private hakkimizdaService: AboutsService,
    private route: ActivatedRoute,
    private domSanit: DomSanitizer,
  ) { }

  ngOnInit(): void {

    this.route.data.subscribe(res => {
      this.route.params.subscribe(data => {
        // console.log(res);
        this.slug = data.slug;
        let service = res.category == 'hizmetler' ? this.servicesService : this.hakkimizdaService;
        this.service = service;
        this.categoryPrefix = `/${res.category}/`;
        this.mainCategorySlug = data.categorySlug;
        this.getDetay(service, data.slug);
        this.getAltCategories(data.categorySlug);


      });
      this.route.queryParams.subscribe(data => {
        this.categoryName = data?.categoryName;
      })
    })


  }

  getDetay(service: BaseService, slug) {
    service.altCategoriesDetail<Base>(slug).subscribe(res => {

      // TODO: SERVICE ISLEMLERI
      this.data = res;
    })
  }

  getSanit(data) {
    return this.domSanit
      .bypassSecurityTrustHtml(data)
  }

  getAltCategories(catSlug) {
    // console.log(catSlug);
    this.service.detail<Base>(catSlug).subscribe(data => {
      this.mainCategory = data;
      this.servicesService.altCategories<Base[]>(data.id).subscribe(res => {
        // TODO: SERVICE ISLEMLERI
        this.altCategories = res;
      })
    })

  }
}
