<div class="{{navbarClass}}">
    <div class="zash-nav">
        <div class="{{navContainer}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="{{logo}}" alt="logo" style="height: 100px; width: 200px; object-fit: contain;">
                </a>

                <button (click)="collapseDiv.classList.toggle('show')" class="navbar-toggler" type="button"
                    data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div #collapseDiv class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link">Ana Sayfa</a>
                        </li>

                        <li class="nav-item" *ngIf="hizmetler.length > 0">
                            <a class="nav-link">HİZMETLERİMİZ <i class="flaticon-down-arrow"></i></a>
                            <ul class="dropdown-menu">

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"
                                    *ngFor="let hizmet of hizmetler">
                                    <a routerLink="/hizmetler/{{hizmet.slug}}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        {{hizmet.title}}
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item" *ngIf="hakkimizda.length > 0">
                            <a class="nav-link">HAKKIMIZDA <i class="flaticon-down-arrow"></i></a>
                            <ul class="dropdown-menu">

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"
                                    *ngFor="let about of hakkimizda">
                                    <a routerLink="/hakkimizda/{{about.slug}}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        {{about.title}}
                                    </a>
                                </li>
                            </ul>
                        </li>


                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <a href="/iletisim" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">İLETİŞİM</a>
                        </li>
                    </ul>


                </div>
            </nav>
        </div>
    </div>
</div>