import { Component, OnInit } from '@angular/core';
import { Setting } from 'src/app/interfaces/setting';
import { SettingService } from 'src/app/services/setting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  uploadUrl = environment.uploadUrl;

  setting: Setting;
  constructor(private settingService: SettingService) {
    this.settingService.list<Setting>().subscribe(res => {
      this.setting = res;
    })
   }

  ngOnInit() {
  }

}
