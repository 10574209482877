import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Setting } from 'src/app/interfaces/setting';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-sozlesme',
  templateUrl: './sozlesme.component.html',
  styleUrls: ['./sozlesme.component.scss']
})
export class SozlesmeComponent implements OnInit {

  data: {sozlesme:string};
  title = '';
  constructor(
    private generalService: GeneralService,
    private acRoute: ActivatedRoute,
    private domSanit: DomSanitizer,
    ) {
    this.acRoute.params.subscribe(res => {
      if (res.slug) {
        this.getSozlesme(res.slug);
      }
    })
  }

  getSanit(data) {
    return this.domSanit
      .bypassSecurityTrustHtml(data)
  }

  getSozlesme(slug) {
    switch(slug) {
      case 'privacy_policy': this.title = 'Gizlilik Sözleşmesi';break;
      case 'terms_and_conditions': this.title = 'Hüküm ve Koşullar';break;
    }
    this.generalService.getSozlesme(slug).subscribe(res => {
      this.data = res;
    })
  }

  ngOnInit(): void {
  }

}
