    <div>
        <div class="">
            <div class="section-title">
                <span class="sub-title">Bilgi için formu doldurunuz</span>
                <h2>Mesaj ilet</h2>
                <!-- <p>Your email address will not be published. We promise not to spam!</p> -->
            </div>
    
            <div class="contact-form">
                <div [formGroup]="form">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 my-1">
                            <div class="form-group">
                                <input formControlName="subject" class="form-control" placeholder="Konu">
                                <span class="text-danger" *ngIf="getError('subject')">
                                    Bu alan zorunludur.
                                </span>
                            </div>
    
                        </div>
    
                        <div class="col-lg-12 col-md-6 my-1">
                            <div class="form-group">
                                <input formControlName="fullName" class="form-control" placeholder="İsim Soyisim">
                                <span class="text-danger" *ngIf="getError('fullName')">
                                    Bu alan zorunludur.
                                </span>
                            </div>
    
                        </div>
    
                        <div class="col-lg-12 col-md-6 my-1">
                            <div class="form-group">
                                <input formControlName="phone" class="form-control"  placeholder="+90 555 555 55 55">
                                <span class="text-danger" *ngIf="getError('phone')">
                                    Bu alan zorunludur.
                                </span>
                            </div>
    
                        </div>
    
                        <div class="col-lg-12 col-md-6 my-1">
                            <div class="form-group">
                                <input formControlName="email" type="email" class="form-control" placeholder="E-posta">
                            </div>
                        </div>
    
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea formControlName="content" class="form-control" cols="30" rows="6"
                                    placeholder="Mesajınız"></textarea>
                                <span class="text-danger" *ngIf="getError('content')">
                                    Bu alan zorunludur.
                                </span>
                            </div>
    
                        </div>
    
                        <div class="col-lg-12 col-md-12">
                            <button (click)="send()" class="default-btn w-100" >Gönder </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
