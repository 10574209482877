<div class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.</p>
                <div class="client">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </div>
            </div>

            <div class="single-feedback-item">
                <p>Quis ipsum suspendisse ultrices gravida. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                <div class="client">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </div>
            </div>

            <div class="single-feedback-item">
                <p>Sed do eiusmod tempor incididunt ut labore et dolore magna, lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis ipsum suspendisse ultrices gravida.</p>
                <div class="client">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>