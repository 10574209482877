<section class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img src="assets/img/error.png" alt="error" style="height: 400px;object-fit: contain;">

                    <h3>Sayfa Bulunamadı</h3>
                    <!-- <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p> -->

                    <a routerLink="/" class="default-btn">Ana Sayfa <span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>