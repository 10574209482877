<app-breadcrumbs [title]="'İletişim'" [subTitle]="'Bize Ulaşın'" [imageType]="'iletisim_banner'"></app-breadcrumbs>


<section class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <span class="sub-title">Bize Ulaşın</span>
                    <!-- <h2>Say Hello</h2>
                    <p>Your email address will not be published. We promise not to spam!</p> -->
                </div>

                <div class="contact-info">
                    <ul class="contact-list">
                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <span>Adres</span>
                            {{contact?.address}}
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>
                            <span>Email</span>
                            <a href="mailto:{{contact?.email1}}" >{{contact?.email1}}</a>
                            <a href="mailto:{{contact?.email2}}" >{{contact?.email2}}</a>
                            <a href="mailto:{{contact?.email3}}" >{{contact?.email3}}</a>

                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            <span>Telefon</span>
                            <a href="tel:{{contact?.phone1}}" >{{contact?.phone1}}</a>
                            <a href="tel:{{contact?.phone2}}" >{{contact?.phone2}}</a>
                            <a href="tel:{{contact?.phone3}}" >{{contact?.phone3}}</a>

                        </li>

                        <li>
                            <div class="icon">
                                <i class="fas fa-fax"></i>
                            </div>
                            <span>Faks</span>
                            <a [href]="safeUrl('fax:' + contact?.fax1)" >{{contact?.fax1}}</a>
                            <a [href]="safeUrl('fax:' + contact?.fax2)" >{{contact?.fax2}}</a>
                        </li>

                    </ul>
                    <ul class="social">
                        <li *ngIf="contact?.facebook"><a [href]="safeUrl(contact?.facebook)" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li *ngIf="contact?.linkedin"><a [href]="safeUrl(contact?.linkedin)" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                        <li *ngIf="contact?.instagram"><a [href]="safeUrl(contact?.instagram)" target="_blank"><i class="flaticon-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                

                <div class="contact-form">
            <app-contact-form></app-contact-form>
                    
                    <!-- <form>
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message <span></span></button>
                            </div>
                        </div>
                    </form> -->
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Konum</span>
            <!-- <h2>Send a Message</h2>
            <p>Your email address will not be published. We promise not to spam!</p> -->
        </div>
        <div class="col-md-12">

            <div class="map-responsive">
                <iframe [src]="safeUrl(contact?.google_embed)" width="600" 
                height="450" frameborder="0"
                 style="border:0" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>