<section class="widget widget_categories" *ngIf="categories.length > 0">
    <h3 class="widget-title"> {{title}} </h3>

    <ul>
        <li *ngFor="let category of categories">
            <a [routerLink]="prefix + category.slug">
                {{category.title}}
            </a>
            <ul class="m-3">
                <li *ngFor="let alt of category.altCategories">
                    <a [routerLink]="prefix + category.slug + '/alt/' + alt.slug"
                        [queryParams]="{categoryName: category.title}">
                        {{alt.title}}
                    </a>
                </li>
            </ul>
        </li>
        <!-- <li><a routerLink="/hizmetler/yetkilendirilmis-yukumlu-statusu">YYS Hizmetlerimiz</a></li>
        <li><a routerLink="/hizmetler/egitim-hizmetlerimiz">Eğitim Hizmetlerimiz</a></li>
        <li><a routerLink="/hizmetler/danismanlik-hizmetlerimiz">Danışmanlık Hizmetlerimiz</a></li> -->

    </ul>
</section>