import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { hizmetler } from 'src/app/data';
import { Base } from 'src/app/interfaces/base';
import { ServicesService } from 'src/app/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hizmetler',
  templateUrl: './hizmetler.component.html',
  styleUrls: ['./hizmetler.component.scss']
})
export class HizmetlerComponent implements OnInit {

  slug: string;

  altCategories: Base[] = [];

  service = this.servicesService;

  uploadUrl = environment.uploadUrl;

  data: Base;
  constructor(
    private servicesService: ServicesService,
    private route: ActivatedRoute,
    private domSanit: DomSanitizer
  ) { }

  ngOnInit(): void {



    this.route.params.subscribe(data => {
      // console.log('slug',data)
      this.slug = data.slug;
      this.getDetay(data.slug);

    })
  }

  getSanit(data) {
    return this.domSanit
      .bypassSecurityTrustHtml(data)
  }

  getDetay(slug) {
    this.servicesService.detail<Base>(slug).subscribe(res => {

      // TODO: SERVICE ISLEMLERI
      this.data = res;
      this.getAltKategori(res.id);
    })
  }


  getAltKategori(id) {
    this.servicesService.altCategories<Base[]>(id).subscribe(res => {

      // TODO: SERVICE ISLEMLERI
      this.altCategories = res;
    })
  }
}
