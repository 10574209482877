import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Base } from '../interfaces/base';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService extends BaseService {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.prefix = 'services/';
  }


  servicesList(): Observable<Base[]> {
    return this.httpClient.get<Base[]>(this.apiUrl + 'services-list');
  }






}
