<div class="page-title-area" style="background-image: url({{uploadUrl}}{{image}});">
    <div class="d-table">
        <div class="d-table-cell">
            <!-- <div class="container">
                <div class="page-title-content">
                    <h2> {{title}} </h2>
                    <ul>
                        <li><a routerLink="/">Ana Sayfa</a></li>
                        <li> {{subTitle || title}} </li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
</div>