import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { setting } from 'src/app/data';
import { Setting } from 'src/app/interfaces/setting';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contact: Setting;

  constructor(private domSanitizer: DomSanitizer, private settingService: SettingService) {
    this.settingService.list<Setting>().subscribe(data => {
      this.contact = data;
    })
  }

  ngOnInit() {
  }


  safeUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
