import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

@Component({
    selector: "app-preloader",
    templateUrl: "./preloader.component.html",
    styleUrls: ["./preloader.component.scss"],
})
export class PreloaderComponent implements OnInit {
    @ViewChild("preloader", { static: false }) preloader: ElementRef;

    constructor() {}

    ngOnInit() {}
}
