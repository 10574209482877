import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Base } from '../interfaces/base';
import { Contact } from '../interfaces/contact';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  private apiUrl = environment.apiUrl;

  constructor(protected httpClient: HttpClient) { }


  postContactForm(data: Contact): Observable<Contact> {
    return this.httpClient.post<Contact>(this.apiUrl + 'iletisim', data);
  }

  get serviceGallery(): Observable<Base[]> {
    return this.httpClient.get<Base[]>(this.apiUrl + 'services-gallery/')
  }


  getImage(imageType: string): Observable<{ image?: string }> {
    return this.httpClient.get<{ image?: string }>(this.apiUrl + 'image/' + imageType)
  }


  get whyUs(): Observable<Base[]> {
    return this.httpClient.get<Base[]>(this.apiUrl + 'why-us/')
  }

  getSozlesme(slug: string): Observable<{sozlesme: string | null}> {
    return this.httpClient.get<{sozlesme: string | null}>(this.apiUrl + 'sozlesme/' + slug); 
  }




}
